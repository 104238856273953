/* chessboard.css */
.dot {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: grey;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none; /* Ensure the dot does not interfere with square clicks */
  }
  